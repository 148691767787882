/* global fbq */
import ReactGA from 'react-ga4';
import { getProductData } from '../components/data';

export default async function iceColdLocal(data) {
    const { eventNameFB, eventNameGA, title, price, name, email, address } = data;

    // Define common parameters
    const timestamp = Date.now();
    const eventID = `${eventNameFB}_${timestamp}`;
    const fbc = localStorage.getItem('fbclid') || null;
    const fbp = localStorage.getItem('_fbp') || null;
    const [firstName, lastName = ''] = name ? name.trim().toLowerCase().split(' ') : ['', ''];

    // Google Analytics Event - Adjusted for GA4 standards

    ReactGA.event({
        action: eventNameGA,
        category: eventNameGA === 'purchase' || eventNameGA === 'add_to_cart' || eventNameGA === 'begin_checkout' ? 'Ecommerce' : 'Lead Generation',
        currency: 'USD',
        value: price,
        ...(title && {
            items: [
                {
                    item_name: title,
                    price: price,
                },
            ]
        }),
    });


    // Facebook Pixel Event Parameters
    const fbqParams = {
        content_name: title,
        currency: 'USD',
        quantity: price > 0 ? 1 : undefined,
        event_id: eventID,
        ...(price && { value: price }),
        ...(title && { content_id: title }),
        ...(email && { em: email }),
        ...(firstName && { fn: firstName }),
        ...(lastName && { ln: lastName }),
        ...(address?.city && { ct: address.city }),
        ...(address?.state && { st: address.state }),
        ...(address?.zip && { zp: address.zip }),
        ...(address?.country && { country: address.country }),
    };

    // Facebook Pixel Event

    fbq('track', eventNameFB, fbqParams);


    // Data for Conversions API
    const capiParams = {
        eventName: eventNameFB,
        event_id: eventID,
        ...(title && { title: title }),
        ...(fbc && { fbc: fbc }),
        ...(fbp && { fbp: fbp }),
        ...fbqParams,
    };

    // Send to Conversions API

    try {
        const response = await fetch('/.netlify/functions/ice-cold', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(capiParams),
        });

        const result = await response.json();

    } catch (error) {
        console.error('Error sending event:', error);
    }

}
