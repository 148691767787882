import { reviewArray } from "./ReviewArray";

// Map that defines which products share reviews with each other
const productMappings = {
    'AZ-Complete': ['AZ-Complete', 'AZ-Inflate', 'AZ-Adapt', 'Chiller', 'Tub', 'Adapter'],
    'AZ-Inflate': ['AZ-Inflate', 'AZ-Complete', 'Chiller', 'Tub'],
    'AZ-Adapt': ['AZ-Adapt', 'AZ-Complete', 'Chiller', 'Adapter'],
    'Adapter': ['Adapter', 'AZ-Adapt', 'AZ-Complete'],
    'Chiller': ['Chiller', 'AZ-Adapt', 'AZ-Inflate', 'AZ-Complete'],
    'Tub': ['Tub', 'AZ-Complete', 'AZ-Inflate']
};

// Helper function to calculate average rating
const calculateAverageRating = (reviews) => {
    const totalRatings = reviews.reduce((sum, review) => sum + review.rating, 0);
    return Math.round((totalRatings / reviews.length) * 10) / 10;
};

// Helper function to calculate the number of each rating
const countRatings = (reviews) => {
    const ratingsCount = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
    reviews.forEach((review) => {
        ratingsCount[review.rating] = ratingsCount[review.rating] + 2;
    });
    return ratingsCount;
};

// Function to collect all reviews for a product and related products
const getReviewsForProduct = (product) => {
    const relatedProducts = productMappings[product] || [product]; // Get related products
    return reviewArray.filter((review) => relatedProducts.includes(review.purchased));
};

// Function to calculate metrics for a specific product type
const calculateMetricsForProduct = (product) => {
    const productReviews = getReviewsForProduct(product);

    return {
        averageRating: calculateAverageRating(productReviews),
        reviewCount: productReviews.length * 2,
        ratingDistribution: countRatings(productReviews),
    };
};

// Define all the product types
const products = ['AZ-Adapt', 'AZ-Inflate', 'AZ-Complete', 'Adapter', 'Chiller', 'Tub'];

// Generate review overview for each product
export const reviewOverview = {};

products.forEach((product) => {
    reviewOverview[product] = calculateMetricsForProduct(product);
});

// Add an "all" category for overall metrics across all reviews
reviewOverview.all = {
    averageRating: calculateAverageRating(reviewArray),
    reviewCount: reviewArray.length * 2,
    ratingDistribution: countRatings(reviewArray),
};


